import React from 'react';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import Colors from '../../consts/Colors';

const LoadingIcon = ({ color = 'primary' }) => {
  return (
    <section className="loadingicon-container">
      <div className="loadingicon-loading">
        <Spinner />
      </div>

      <style jsx>{`
        section {
          width: 100%;
          height: calc(100vh - 70px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        div {
          height: 140px;
          width: 140px;
        }

        div :global(.cls-1) {
          fill: ${color === 'secondary' ? Colors.YELLOW : Colors.TEXT_GREY};
        }
      `}</style>
    </section>
  );
};

export default LoadingIcon;

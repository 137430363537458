/*** DOCUMENTION: See ./README.md ***/

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ButtonContainer from '../layout/ButtonContainer';
import MTFormText from './controls/MTFormText';
import Consts from '../../consts/Consts';
import FormErrors from '../../consts/FormErrors';
import Colors from '../../consts/Colors';
import MTButton from '../button/MTButton';
import { trackEvent } from '../../utils/appInsight';
import { useLocation, useHistory } from 'react-router-dom';
import AppContext from '../../app/context/AppContext';
import MTRequired from './misc/MTRequired';

//UPCOMING SPRINT:
//JP: Shared yup - Move few existing to addMethod as need to share, create new with addMethod
//JP: When use .trim().required() together again, create MTYup method/const. Or override required to not accept spaces/tabs only?

const MTForm = ({
  children, //Expects <MTTextInput>, <MTSelect>, <MTTextarea>, etc. or modules that include these as a child and pass props thru
  dynamicChildren, //Used for components that aren't in the first render - Expects an array of objects and assumes any name match with children is the same form component.
  onSubmit,
  cancelButtonOnClick,
  submitLabel = 'Submit',
  showRequired,
  formName = 'default',
  cancelLabel = 'Cancel',
  enableReinitialize = false, //WARNING: Used for Admin Site (QA Users) only, since it loads form data via API call, but has an issue where if invalid data it doesn't immed invalidate the form.
  showSubmitButton = true,
  fieldsToValidateOnLoad = {}, // Sets internal formik initialTouched for the field(s), so an error is shown without the user needing to click out of the field.
  showBackButton = false,
  refreshOrders = true,
  refreshTickets = true,
  alignLeft = false,
  isCarrierATTOrTMobile = false, // since we dont want to err out field and then disable the button so we need to pass a separate flag.
}) => {
  const initialValues = {};
  const initialErrors = {};
  let hasRequiredFields = false;
  const yupValues = {};

  const {
    setIsNewTicketCreated,
    setOrderPlaced,
    isOnInitialRoute,
  } = useContext(AppContext);

  const getNameError = name => {
    return `Error: MTForm children components must all define a unique name prop via defaultProps or inline attribute. name: ${
      name ? name : 'empty/null'
    }`;
  };

  const addValues = children => {
    React.Children.forEach(children, child => {
      //Check if child is defined, since it can be null or empty string from ?: or && in JSX
      if (child && child.props) {
        //Check if it's a MTFormText element, if so skip it, since it's not a true control.
        if (child.type === MTFormText) {
          return;
        } else if (
          child.props.children &&
          String(child.type) === 'Symbol(react.fragment)'
        ) {
          //If the child is a React.Fragment, pass it to addValues to loop over its children.
          addValues(child.props.children);
        } else if (!child.props.name) {
          //Throw an error that all must have a name prop
          throw new Error(getNameError(child.props.name));
        } else if (initialValues.hasOwnProperty(child.props.name)) {
          //Throw an error that all names must be unique.
          throw new Error(getNameError(child.props.name));
        } else if (
          !child.props.initialValue &&
          child.props.initialValue !== Consts.EMPTY &&
          child.props.initialValue !== 0
        ) {
          //Throw an error to force initialValue to be defined or EMPTY, since it's required for <Formik>.
          throw new Error(
            `Error: MTForm children components must define initialValue via defaultProps or inline attribute. name: ${child.props.name}`
          );
        } else {
          initialValues[child.props.name] = child.props.initialValue;
          const yup = child.props.yup;
          if (yup) {
            yupValues[child.props.name] = yup;
            const isRequired =
              yup._exclusive && Boolean(yup._exclusive.required);

            //In order to start submit disabled, we need to provide a list of fields that have an error to start.
            //We do need to exlude those that start with an initialValue, so they aren't considered to have an error.
            if (isRequired) {
              if (child.props.initialValue === Consts.EMPTY) {
                initialErrors[child.props.name] = true;
              }
              hasRequiredFields = true;
            }
          }
        }
      }
    });
  };

  const addDynamicValues = childList => {
    childList.forEach(child => {
      //Once the component is added, skip its dynamic value.
      if (initialValues[child.props.name] === undefined) {
        if (!child.props.name) {
          //Throw an error that all must have a name prop
          throw new Error(getNameError(child.props.name));
        } else if (initialValues.hasOwnProperty(child.props.name)) {
          //Throw an error that all names must be unique.
          throw new Error(getNameError(child.props.name));
        } else if (
          !child.props.initialValue &&
          child.props.initialValue !== Consts.EMPTY &&
          child.props.initialValue !== 0
        ) {
          //Throw an error to force initialValue to be defined or EMPTY, since it's required for <Formik>.
          throw new Error(
            `Error: MTForm dynamicChildren objects must define initialValue via defaultProps or second argument to create. name: ${child.props.name}`
          );
        } else {
          initialValues[child.props.name] = child.props.initialValue;
        }
      }
    });
  };

  //Loop over the children elements and capture their initial value and yup value
  addValues(children);

  //Loop over the dynamic children elements and capture their initial value. yup will be captured only once it's in the DOM.
  if (dynamicChildren) {
    addDynamicValues(dynamicChildren);
  }

  //Yup validationSchema
  const validationSchema = Yup.object().shape(yupValues);

  const maxWidth = 600;

  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validationSchema}
        onSubmit={async formikValues => {
          return onSubmit(formikValues).then(() => {
            if (refreshOrders) {
              setOrderPlaced(true);
            }
            if (refreshTickets) {
              setIsNewTicketCreated(true);
            }
          });
        }}
        enableReinitialize={enableReinitialize}
        initialTouched={fieldsToValidateOnLoad}
      >
        {({ isValid, isSubmitting, values }) => {
          return (
            <>
              <Form
                className={`mtForm${alignLeft ? ' alignLeft' : Consts.EMPTY}`}
                data-testid="formik-form"
              >
                {showRequired && hasRequiredFields && (
                  <MTFormText className="requiredLabel">
                    <MTRequired />
                    {FormErrors.REQUIRED_KEY}
                  </MTFormText>
                )}
                {children}
                {(cancelButtonOnClick ||
                  showBackButton ||
                  showSubmitButton) && (
                  <ButtonContainer>
                    {cancelButtonOnClick && (
                      <MTButton
                        onClick={() => {
                          cancelButtonOnClick();
                          trackEvent(
                            `MTForm Cancel Button clicked on - ${location.pathname}`
                          );
                        }}
                        darkButton={false}
                      >
                        {cancelLabel}
                      </MTButton>
                    )}
                    {showBackButton && !isOnInitialRoute && (
                      <MTButton
                        onClick={() => {
                          history.goBack();
                          trackEvent(
                            `MTForm Back Button clicked on - ${location.pathname}`
                          );
                        }}
                        darkButton={false}
                      >
                        Back
                      </MTButton>
                    )}
                    {showSubmitButton && (
                      <MTButton
                        disabled={
                          !isValid || isSubmitting || isCarrierATTOrTMobile
                        }
                        type="submit"
                        isSubmitting={isSubmitting}
                      >
                        {submitLabel}
                      </MTButton>
                    )}
                  </ButtonContainer>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
      <style jsx>
        {`
          :global(.mtForm) {
            width: 100%;
            min-width: 280px;
            max-width: ${maxWidth}px;
            box-sizing: border-box;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          :global(.mtForm.alignLeft) {
            margin-left: 0;
            padding-left: 0;
            max-width: 673px;
          }

          :global(#mytech-dialog .mtForm) {
            min-width: 250px;
          }

          :global(.mtForm .requiredLabel) {
            color: ${Colors.TEXT_GREY};
          }

          :global(.mtForm .requiredLabel > span:first-child) {
            padding-left: 0;
            padding-right: 4px;
            color: inherit;
          }

          :global(::-webkit-input-placeholder) {
            color: ${Colors.PLACEHOLDER_GREY};
          }

          :global(::-moz-placeholder) {
            color: ${Colors.PLACEHOLDER_GREY};
          }
          @media (min-width: ${maxWidth + 40}px) {
            :global(.mtForm) {
              padding: 0 20px;
            }
          }
        `}
      </style>
    </>
  );
};

export default MTForm;

MTForm.propTypes = {
  children: PropTypes.node.isRequired, //Expects <MTTextInput>, <MTSelect>, <MTTextarea>, etc. or modules that include these as a child and pass props thru
  onSubmit: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
  cancelButtonOnClick: PropTypes.func,
  submitLabel: PropTypes.string,
};

MTForm.defaultProps = {
  showRequired: true,
};

//Pass the component that has defaultProps, optionally pass {name, initialValue, yup} overrides using the second argument
MTForm.createDynamicChild = (
  MTFormComponent,
  { name, initialValue, yup } = {}
) => {
  const defaultProps = MTFormComponent.defaultProps;
  return {
    props: {
      name: name ? name : defaultProps.name,
      initialValue: initialValue ? initialValue : defaultProps.initialValue,
      yup: yup ? yup : defaultProps.yup,
    },
  };
};

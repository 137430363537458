import Consts from './Consts';

const Events = {
  GET_HIRED: {
    HARDWARE_ACCESSORIES: 'Hardware and Accessories event Clicked',
    PC_SOFTWARE: 'PC Software event Clicked',
    MOBILE_HOTSPOT: 'Mobile Hotspot event Clicked',
    BYOD: 'Bring your Own Device(BYOD) event Clicked',
    DIGITAL_WORKSPACE: 'Digital Workspace event Clicked',
    CISCO_ANYCONNECT: 'Cisco AnyConnect event Clicked',
    ZOOM: 'Zoom event Clicked',
    MICROSOFT_OUTLOOK: 'Microsoft Outlook event Clicked',
  },

  HOW_DID_YOU_HEAR_ABOUT_US: {
    LANDING: 'How Did You Hear About Us - Landing',
    CANCELLED: 'How Did You Hear About Us - Cancelled',
    SUBMITTED: 'How Did You Hear About Us - Submitted',
  },

  CSAT_FEEDBACK: {
    LANDING: 'Feedback Dialog - Landing',
    SUBMITTED: 'User feedback submitted',
    CLOSED_NOT_NOW: 'Not interested to submit feedback - Not now',
    CLOSED_DIALOG: 'Not interested to submit feedback - Closed Dialog',
    SUBMITTED_WITH_1_STAR: '1 star given for feedback',
    SUBMITTED_WITH_2_STAR: '2 star given for feedback',
    SUBMITTED_WITH_3_STAR: '3 star given for feedback',
    SUBMITTED_WITH_4_STAR: '4 star given for feedback',
    SUBMITTED_WITH_5_STAR: '5 star given for feedback',
  },
  ORDERING_FEEDBACK: {
    LANDING: flow => `Ordering ${flow} Feedback Dialog - Landing`,
    CLOSED: flow => `Ordering ${flow} Feedback Dialog - Closed`,
    SUBMITTED: flow => `Ordering ${flow} Feedback Dialog - Submitted`,
    SUBMITTED_STARS: (flow, numStars) =>
      `Ordering ${flow} Feedback - ${numStars} star given for feedback`,
  },
  MOBILE_COMMON: {
    EXTEND_INTERNATIONAL: 'Mobile Management - Extend International',
    APPLY_INTERNATIONAL: 'Mobile Management - Apply International Plan',
    EXTEND_LOANER: 'Mobile Management- Extend Loaner',
    ORDER_PLACED: 'Order Placed',
    ORDER_DETAILS: 'Order Details Clicked for type - ',
    TICKET_DETAILS: 'Ticket Details Clicked for type - ',
    IPAD_DIALOG_CLOSED: 'IPad Dialog Closed',
  },

  SIM: {
    NEW_SUBMIT: 'SIM New Card submit button clicked',
    ACTIVATION_SUBMIT: 'SIM Card Activation submit button clicked',
  },

  IPAD: 'Ipad Device Ordered',
  SECURITY_ACCESS: {
    CHANGE_PASSWORD: '"Change Password" button',
    MANAGE_SETTINGS_2SV: '2SV "Manage Settings" button',
    MANAGE_SETTINGS_SSPR: 'SSPR "Manage Setting" button',
    FAQ_2SV: '2SV FAQs pop up dialog box',
    FAQ_SSPR: 'SSPR FAQs pop up dialog box',
    FAQ_PASSWORD_INFO: 'Password Information FAQs pop up dialog box',
    FAQ_PHISHING: 'Phishing FAQ pop up dialog box',
    FAQ_BUTTON: 'FAQ Button',
    CHANGE_PASSWORD_INSTRUCTIONS: 'Change Password pop up dialog box',
    PHISHING_ADDITIONAL_DETAILS: 'Phishing "Additional Details" button',
    ULEARN_TRAINING_CHECK: 'Check for required trainings section',
    ULEARN_TRAINING_US_ASSOCIATES: 'ULearn "US Associates" button',
    ULEARN_TRAINING_INTL_ASSOCIATES: 'ULearn "INTL Associates" button',
    ULEARN_TRAINING_VENDOR: 'ULearn "VENDOR" button',
  },

  CORPORATE: {
    ORDER_SUBMITTED_MYSELF: 'Corporate Device Ordered - Myself',
    ORDER_SUBMITTED_ANOTHER: 'Corporate Device Ordered - Another',
    MYSELF_POPUP_BYOD:
      'Corporate Device Myself -> BYOD pop-up "Order Corporate Device"',
  },

  LOANER: {
    ORDER_SUBMITTED_MYSELF: 'Loaner Device Ordered - Myself',
    ORDER_SUBMITTED_ANOTHER: 'Loaner Device Ordered - Another',
    MYSELF_POPUP: 'Loaner Device Myself -> pop-up "Order Loaner"',
  },

  INTERNATIONAL: {
    ORDER_SUBMITTED_ANOTHER: 'International for another submitted',
    ORDER_SUBMITTED_MYSELF: 'International for Myself submitted',
    ORDER_SUBMITTED_MYSELF_ATT:
      'International for Myself submitted with ATT carrier choice',
  },

  CANCELLATION: {
    ORDER_SUBMITTED_ANOTHER: 'Cancel for another submitted',
    ORDER_SUBMITTED_MYSELF: 'Cancel for Myself submitted',
    ORDER_PLACED: 'Cancel Service Order Placed',
  },

  EAGLE_HOME_DEVICE: {
    ORDER_SUBMITTED_MYSELF: 'Eagle home device order for Myself submitted',
  },

  ORDERS: {
    NO_ORDERS_LANDING: 'No Orders Component - Landing',
  },

  DEVICES: {
    UPGRADE_ACTION: 'Upgrade action button clicked',
    UNENROLL_ACTION: 'Unenroll action button clicked',
    RETURN_ACTION: 'Return action button clicked',
    POWER_ACTION: 'Power action button clicked',
    LOST_STOLEN_ACTION: 'Lost/Stolen action button clicked',
    HELP_ACTION: 'Help action button clicked',
    DAMAGED_ACTION: 'Damaged action button clicked',
    CANCEL_ACTION: 'Cancel action button clicked',
    RETURN_CLOSED: 'Return Device Dialog Closed',
    DIALOG_CLOSED: ' dialog closed',
    DEVICES_INFO_OPENED: 'Devices Information Dialog opened',
    DEVICES_INFO_CLOSED: 'Devices Information Dialog Closed',
    SUPPORT_ACTION: 'Support Button clicked',
    CHANGE_2FA_ACTION: 'Change 2FA Button clicked',
    RESTORE_ACTION: 'Restore VDI Button clicked',
    REBOOT_ACTION: 'Reboot VDI Button clicked',
    REQUEST_ADMIN_ACTION: 'Request Admin Button clicked',
    INFO_DIALOG_CLOSED: 'My Devices Info Dialog Closed',
  },

  TRACK_DEVICE_TYPES: {
    BYOD: 'BYOD',
    CORPORATE: 'Corporate Mobile',
    VDI: 'VDI',
    MAC: 'Mac',
    PC: 'Pc',
    TABLET: 'Tablet',
    LOANER: 'Loaner',
  },

  ONBOARDING: {
    SECURITY_FINISH_DIALOG: 'Security Onboarding Completion Dialog Closed',
  },

  TECH_SUPPORT: {
    VDI_SUBMIT: 'VDI Support form submitted',
    HOME_OFFICE_SUBMIT: 'Home Office Tech Support form submitted',
    VIDEO_CONF_SUBMIT: 'Video Conf Technical Support form submitted',
    APPLICATION_SUBMIT: 'Application Technical Support form submitted',
    DIGITAL_SIGNAGE: 'Digital Signage Technical Support form submitted',
    OTHER_SUBMIT: 'Other Technical Support form submitted',
  },

  HOTSPOT: {
    NEW_REQUEST_SUBMIT: 'Hotspot new request submitted',
    REPLACEMENT_SUBMIT: 'Hotspot replacement submitted',
  },
  MY_TICKETS: {
    SNOW_STATUS: 'ServiceNow Status Button clicked',
  },

  DASHBOARD_FEEDBACK: (dashboard, type) => {
    return `${dashboard} Feedback - ${type}`;
  },

  VIDEO_DIALOG: (action, title) => {
    return `${action} Video Dialog - ${title}`;
  },

  VIDEO_CAROUSEL: details => {
    return `Video Carousel - ${details}`;
  },
  VIDEO_CAROUSEL_NEXT_BUTTON: 'Next navigation button clicked',
  VIDEO_CAROUSEL_PREVIOUS_BUTTON: 'Previous navigation button clicked',
  VIDEO_CAROUSEL_DIRECT_SEARCH: videoIdFromSearch => {
    return `Video searched directly via search param. Search Param Video Id: ${videoIdFromSearch}`;
  },
  VIDEO_CAROUSEL_INTERNAL_SEARCH: searchParam => {
    return `Searched video does not exist. Search Param: ${searchParam}`;
  },
  VIDEO_CAROUSEL_SHARE_BUTTON_COPY: url => {
    return `Successfully copied ${url} to clipboard`;
  },
  VIDEO_CAROUSEL_SHARE_BUTTON_CLICKED: 'Share button clicked',
  VIDEO_CAROUSEL_ITEM_CLICKED: item => {
    return `Video item clicked in Carousel. Video: ${item}`;
  },
  TICKET_COMMENTS_DIALOG: {
    LANDING: 'Ticket Comments Dialog - Landing',
    CLOSED: 'Ticket Comments Dialog - Close button clicked',
  },
  ATTACHMENTS_DIALOG: {
    LANDING: 'Ticket Attachments Dialog - Landing',
    UPLOAD_STARTED: 'Ticket Attachments Dialog - Upload Started',
    CLOSED: 'Ticket Attachments Dialog - Close button clicked',
  },
  HARDWARE_SHIPMENT_TRACKER: {
    TAB: label => `Hardware Shipment Tracker - ${label} tab clicked`,
    ALL_REQUESTS_SEARCH:
      'Hardware Shipment Tracker - All Requests search clicked',
    FILTER: label => `Hardware Shipment Tracker - ${label} filter clicked`,
    FILTER_APPLIED: label =>
      `Hardware Shipment Tracker - Requests filtered by ${label}`,
  },
  ERRORS: {
    TITLE: {
      ERROR_PAGE: 'Error page error',
      CANCEL_SERVICE: 'Cancel Service error',
      INTERNATIONAL: 'International order error',
      INTERNATIONAL_ANOTHER: 'International for another error',
      CANCEL_ANOTHER: 'Cancel Service for another error',
      CORPORATE_MYSELF: 'Corporate myself request error',
      CORPORATE_ANOTHER: 'Corporate another request error',
      EAGLE_HOME_DEVICE: 'Corporate eagle home request error',
      LOANER_MYSELF: 'Loaner myself request error',
      LOANER_ANOTHER: 'Loaner another request error',
      NEW_SIM: 'New SIM request error',
      SIM_ACTIVATION: 'SIM Activation request error',
      GRAPHQL: 'GraphQL call failed',
      AXIOS: 'Axios call failed',
      NETWORK: "Network Error - Couldn't reach the BE.",
      IPAD: 'Ipad request error',
      NETWORK_RETRY: 'Network Error - Retrying again',
      VDI_TECH_SUPPORT: 'VDI Support order error',
      HOME_OFFICE_TECH_SUPPORT: 'Home Office Tech Support order error',
      APPLICATION_TECH_SUPPORT: 'Application Tech Support order error',
      DIGITAL_SIGNAGE_TECH_SUPPORT: 'Digital Signage Tech Support order error',
      OTHER_TECH_SUPPORT: 'Other Tech Support order error',
      VIDEO_CONF_TECH_SUPPORT: 'Video Conf Tech Support order error',
      SESSION_EXPIRED: 'Session Expired',
      HOTSPOT: 'Hotspot order error',
      ADD_COMMENT: 'Add comment error',
      IPAD_FETCH_STORE_INFO: 'Ipad Fetch Store Information Error',
      VIRTUAL_PC_REQUEST_DATA: 'Virtual PC request data error',
      VIRTUAL_PC_CHANGE_2FA: 'Virtual PC change 2FA request error',
      SHIPMENT_TRACKER_USER_DATA: 'Shipment Tracker user data error',
      SHIPMENT_TRACKER_TRACKING_DATA: 'Shipment Tracker tracking data error',
      VDI_REQUEST_DATA: 'VDI request data error',
      VDI_CHANGE_2FA: 'VDI change 2FA request error',
      READ_BLOB_STORAGE_DATA: 'Read blob data stream API error',
      UPLOAD_BLOB_STORAGE_DATA: 'Upload blob data API error',
      FETCH_PDF_DATA_BLOB_STORAGE: 'Blob storage data fetching for pdfs error',
      FETCH_IMAGE_DATA_BLOB_STORAGE:
        'Blob storage data fetching for image error',
    },
    MESSAGES: {
      CANCEL_SERVICE: 'There was an error with your cancellation request',
      INTERNATIONAL:
        'There was an error with your international service request.',
      INTERNATIONAL_ANOTHER:
        'There was an error with your international service request.',
      CANCEL_ANOTHER: 'There was an error with your cancellation request.',
      CORPORATE_MYSELF:
        'There was an error with your corporate myself request.',
      CORPORATE_ANOTHER:
        'There was an error with your corporate another request.',
      EAGLE_HOME_DEVICE:
        'There was an error with your eagle home device request.',
      LOANER_MYSELF: 'There was an error with your loaner myself request.',
      LOANER_ANOTHER: 'There was an error with your loaner another request.',
      NEW_SIM: 'There was an error with your New SIM request.',
      SIM_ACTIVATION: 'There was an error with your SIM Activation request.',
      VDI_TECH_SUPPORT: 'There was an error with your VDI Support request',
      HOME_OFFICE_TECH_SUPPORT:
        'There was an error with your Home Office Tech Support request',
      VIDEO_CONF_TECH_SUPPORT:
        'There was an error with your Video Conf Tech Support request',
      OTHER_TECH_SUPPORT:
        'There was an error with your Other Tech Support request',
      APPLICATION_TECH_SUPPORT:
        'There was an error with your Application Tech Support request',
      DIGITAL_SIGNAGE_TECH_SUPPORT:
        'There was an error with your Digital Signage Tech Support request',
      DEVICE_LOOKUP: 'There was an error with your Device lookup request',
      FOUND_DEVICE:
        'There was an error with your Found Device - return to HO request',
      HOTSPOT: 'There was an error with your Hotspot request',
      ADD_COMMENT: 'There was an error with your Add Comment request',
      IPAD_FETCH_STORE_INFO:
        'There was an error while fetching the store information for Ipad form',
      IPAD: 'There was an error with the Ipad request',
    },
  },
  HEADER: {
    MYTECH_LOGO: (isInLiteMode, toRoute) => {
      return `MyTech${
        isInLiteMode ? ' Lite' : Consts.EMPTY
      } Logo clicked to ${toRoute}.`;
    },
    MOBILE_NAV_MENU_ICON: `Mobile navigation menu button clicked.`,
    USER_SETTINGS_ICON: 'User Settings menu button clicked.',
  },
  SIDE_NAV: {
    NAV_ITEM: label => {
      return `Nav - ${label} clicked`;
    },
  },
  ALERTBANNER: {
    ACTION_LINK: (href, redirect) => {
      return `AlertBanner Link ${
        redirect ? 'redirected' : 'opened new page'
      } to ${href}`;
    },
    PREVIOUS_CHEVRON_CLICKED: `AlertBanner previous chevron clicked`,
    NEXT_CHEVRON_CLICKED: `AlertBanner next chevron clicked`,
    CONTENT_CIRCLE_CLICKED: alertTitle => {
      return `AlertBanner Content button directly visited alert titled: ${alertTitle}`;
    },
    AUTOPLAY_TOGGLE_CLICKED: isPlaying => {
      return `AlertBanner Autoplay button clicked to ${
        isPlaying ? 'continue' : 'pause'
      }.`;
    },
  },
  VDI_NO_2FA_ALERT: 'VDI No 2FA alert action button clicked.',
  MTLD3BUTTON_CLICK: (buttonType, to, from) => {
    return `${buttonType} redirected to ${to} - ${from}`;
  },
  MTBACKBUTTON_CLICK: locationPath => {
    return `Back button clicked - ${locationPath}`;
  },
  TECH_BAR_BUTTON_CLICK: label => {
    return `Virtual Techbar Button clicked - label: ${label}`;
  },
  SUCCESSFUL_ORDER_DIALOG: {
    DEFAULT_CLOSE: 'Successful Order Dialog - Closed using x or Overlay',
    ACTION_BUTTON_CLOSE: 'Successful Order Dialog - Closed using action button',
    FILEPOND_FILES_ATTACHED: attachmentsLength =>
      `Successful Order Dialog - ${attachmentsLength} images attached for upload`,
    LANDING: 'Successful Order Dialog - Opened',
  },
  FAILED_ORDER_DIALOG: {
    DEFAULT_CLOSE: 'Failed Order Dialog - Closed using x or Overlay',
    ACTION_BUTTON_CLOSE: 'Failed Order Dialog - Closed using action button',
    LANDING: 'Failed Order Dialog - Opened',
  },
  FILEPOND_UPLOAD: {
    ALL_FILES_UPLOADED: 'FilePond Upload - all files processed',
    RETRY_FILE_FOUND: 'FilePond Upload - Retry file found',
    NO_RETRY_FILES_FOUND: 'FilePond Upload - no retry files found',
    UPLOAD_FAILED: 'FilePond Upload - Failed to upload file',
  },
  FILEPOND_EVENT: eventString => {
    return `FilePond - ${eventString}`;
  },
  FILEPOND_SUCCESSFUL_FILE_UPDATE: 'Successfully updated files',
  TECH_BAR_LOCATIONS_VIEW_ALL_OPEN_LOCATIONS_CHECKBOX: isChecked => {
    return `Tech Bar Locations - Show open locations only. Checked: ${isChecked}`;
  },
  TOOLTIP: id => {
    return `Tooltip opened for identifier: ${id}`;
  },
};

export default Events;

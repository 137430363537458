import * as React from 'react';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';

const customStyles = {
  containerStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};

const LDLoading = () => {
  return (
    <div style={customStyles.containerStyles}>
      <Spinner style={{ width: 50, height: 50 }} />
    </div>
  );
};

export default LDLoading;

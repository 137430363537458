import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Events from '../../consts/Events';
import { trackPage, trackEvent } from '../../utils/appInsight';
import Consts from '../../consts/Consts';
import {
  ErrorMessage,
  Modal,
  Grid,
  GridColumn,
} from '@walmart-web/livingdesign-components';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import Dialogs from '../../consts/Dialogs';
import MTVideoCarouselShareButton from '../carousel/MTVideoCarouselShareButton';

const VideoDialog = ({
  isDialogOpen,
  closeDialog,
  videoSrc = Consts.EMPTY,
  videoTitle = Consts.SINGLE_SPACE,
  videoSearchError = false,
  showShareButton = false,
}) => {
  const showLoading =
    !videoSearchError &&
    videoTitle === Consts.SINGLE_SPACE &&
    videoSrc === Consts.EMPTY;
  const showIframe = !showLoading && !videoSearchError;

  const eventData = {
    src: videoSrc,
    title: videoTitle,
  };

  useEffect(() => {
    if (isDialogOpen) {
      trackPage({
        name: Events.VIDEO_DIALOG('Opened', eventData),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    trackEvent(Events.VIDEO_DIALOG('Closed', eventData));
    closeDialog();
  };

  return (
    <Modal
      isOpen={isDialogOpen}
      onClose={handleCloseDialog}
      size="large"
      title={videoTitle}
    >
      <Grid>
        <GridColumn UNSAFE_style={{ width: '100%', textAlign: 'center' }}>
          {showLoading && (
            <Spinner
              className="videodialog-spinner"
              style={{ width: 50, height: 50 }}
            />
          )}
          {showIframe && (
            <>
              <iframe
                style={{ border: 'none', width: '100%', height: '450px' }}
                title={videoTitle}
                src={videoSrc}
                allowFullScreen
              ></iframe>
            </>
          )}
          {videoSearchError && (
            <ErrorMessage
              title={Dialogs.VIDEO_CAROUSEL_SEARCH_ERROR_DIALOG.TITLE}
            >
              {Dialogs.VIDEO_CAROUSEL_SEARCH_ERROR_DIALOG.MESSAGE}
            </ErrorMessage>
          )}
        </GridColumn>
        <GridColumn UNSAFE_style={{ textAlign: 'right' }}>
          <MTVideoCarouselShareButton
            isHidden={!showIframe || !showShareButton}
          />
        </GridColumn>
      </Grid>
    </Modal>
  );
};

export default VideoDialog;

VideoDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  videoSrc: PropTypes.string,
  videoTitle: PropTypes.string,
  videoSearchError: PropTypes.bool,
};

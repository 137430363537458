import { Button } from '@walmart-web/livingdesign-components';
import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import useMTLD3Button from '../../customHooks/useMTLD3Button';
import { ReactNode } from 'react';
const BUTTON_TYPE = 'Button';

// See: https://livingdesign.walmart.com/components/button/react/ for LD3 Button Details
/**
 * Below props object that can be passed
 * @param {ReactNode} children - LD3 Prop for the content of the button
 * @param {string} href - LD3 Prop for new page ancor redirection
 * @param {boolean} disabled - LD3 Prop for disabling the button
 * @param {boolean} isFullWidth - LD3 Prop for making the button be the full with of its container
 * @param {ReactNode} leading - LD3 Prop for what can be applied to the leading edge of the content in the button
 * @param {string} size - LD3 Prop for buttoon size
 * @param {ReactNode} trailing - LD3 Prop for what can be applied to the trailing edge of the content in the button
 * @param {string} variant - LD3 Prop for the type of button to be shown
 * @param {string} className - UNSAFE LD3 Prop
 * @param {object} style - UNSAFE LD3 Prop
 * @param {Function} onClick - HTML BUTTON Prop
 * @param {boolean} newTab - MyTech Prop used with href LD3 Prop to open in a new page
 * @param {Function} track - MyTech Prop used for TrackEvents
 * @param {string} to - MyTech Prop used for internal site navigation
 * @param {object} toState - MyTech Prop used for passing state via react-router-dom
 * @param {boolean} isSubmitting - MyTech Prop used to show the loading spinner and disable the button
 * @returns MyTech feature embedded LD3 Button
 */

const MTLD3Button = props => {
  const { handleClick, ancorProps, spinnerColor } = useMTLD3Button({
    ...props,
    buttonType: BUTTON_TYPE,
  });
  const {
    children,
    disabled,
    isFullWidth,
    leading,
    size,
    trailing,
    variant,
    className,
    style,
    isSubmitting,
  } = props;

  /* istanbul ignore next */
  const pimaryOrDestructiveVariant =
    variant === 'primary' || variant === 'destructive';
  /* istanbul ignore next */
  const spinnerFillColor = pimaryOrDestructiveVariant ? '#fff' : 'unset';

  /* istanbul ignore next */
  const determineSpinnerWidthAndHeight = () => {
    if (!size || size === 'small') {
      return { width: 20, height: 20 };
    } else if (size === 'medium') {
      return { width: 25, height: 25 };
    } else if (size === 'large') {
      return { width: 30, height: 30 };
    }
  };

  return (
    <Button
      disabled={disabled || isSubmitting}
      isFullWidth={isFullWidth}
      leading={
        isSubmitting ? (
          <>
            <Spinner
              className="button-spinner"
              style={{ ...determineSpinnerWidthAndHeight() }}
            />
            <style>{`
              .button-spinner .spinner {
                fill: ${spinnerFillColor};
              }
            `}</style>
          </>
        ) : (
          leading
        )
      }
      size={size}
      trailing={trailing}
      variant={variant}
      UNSAFE_className={className}
      UNSAFE_style={style}
      onClick={handleClick}
      {...ancorProps}
      {...props}
    >
      {children}
    </Button>
  );
};

MTLD3Button.defaultProps = {
  disabled: false,
  isFullWidth: false,
  size: 'medium',
  variant: 'primary',
  isSubmitting: false,
};

export default MTLD3Button;
